<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import tableData from "@/components/tablecomponent";
import ChangeDate from "@/components/changeDateComponent";
import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
/**
 * Advanced-table component
 */

export default {
  page: {
    title: "ใบส่งมอบรถ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableData,
    ChangeDate,
    btnComponent,
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      teamOption: [],
      teamSearch: "",
      title: "ใบส่งมอบรถ",
      items: [
        {
          text: "ฝ่ายขาย",
        },
        {
          text: "ใบส่งมอบรถ",
          active: true,
        },
      ],

      /*error: "please input data",
      Testpost: false,
      testpost:{
        name:"",
        job:"",
      },*/
      optionStatus: [
        {
          status: "APV",
          name: " ยืนยันการรับแล้ว ",
        },
        {
          status: "DFT",
          name: " ร่าง ",
        },
      ],
      localDataBranch: [],
      localData: [],
      overlayFlag: false,
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      total: "",
      rowData: [], //? Data ที่ต่อ API
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      // filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      selectedUsers: [],
      isSelectedAll: false,
      selectMode: "multi",
      selected: [],
      filter: [
        {
          returnCode: "",
        },
      ],
      status: "",
      DateSearch: "",
      returnCode: "",
      branchIdSearch: "",
      cusNameSearch: "",
      cusFamilyNameSearch: "",
      vehicleSearch: "",

      // packageId,

      fields: [
        {
          key: "saleDate",
          label: "วันที่",
        },
        {
          key: "saleCode",
          sortable: true,
          label: "รหัสส่งมอบ/ขายรถ",
        },
        {
          key: "bookingCode",
          sortable: true,
          label: "รหัสใบเสนอราคา",
        },
        {
          key: "productNameEn",
          sortable: true,
          label: "รุ่นรถ",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          label: "นามสกุล",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ยอดสุทธิ",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },

        {
          key: "action",
          label: "แก้ไข | ลบ",
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    //
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.rowData.length;
  },

  created() {
    this.getLocalData();
    this.getData();
    this.getTeam();
  },
  methods: {
    printDocument(saleId) {
      this.overlayFlag = true;
      useNetw
        .get("api/vehicle-sale/print", {
          params: {
            saleId: saleId,
          },
        }) // ?เอามาจากไหน
        .then((data) => {
          // // console.log(data.data.url);
          this.linkPtintFlowslot = data.data.url;
          // window.open(data.data.url, '_blank');
          window.location = data.data.url;
          // this.$refs["modalPrintflowSlot"].show();
          // window.open = data.data.url;
          // this.loading = false;
          this.$refs["modalPrintflowSlot"].hide();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message != null
                ? err.response.data.message
                : appConfig.serverError
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getTeam: function(event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/test-drive/dealer-team", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchIdSearch.branchId,
            name: event,
          },
        })
        .then((response) => {
          this.teamOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
      // // console.log(this.localDataBranch);
    },
    handleSearch() {
      this.getData();
      //
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/vehicle-sale", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: [this.branchId],
            saleDate: this.DateSearch,
            teamId: this.teamSearch.teamId,
            // status: this.status.status,
            // returnCode: this.returnCode,
            // customerNameTh: this.cusNameSearch,
            // customerFamilyNameTh: this.cusFamilyNameSearch,
            // licensePlate: this.vehicleSearch,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          //?ได้รับการตอบกลับ
          //?ถ้าได้ข้อมูลมาหรือได้รับการตอบกลับให้ทำอะไร
          // this.packageId = response.data.data.packageId;
          this.rowData = response.data.data; //?ข้อมูล
          this.per_page = response.data.per_page; // ?จำนวนข้อมูลที่แสดงต่อหน้า
          this.page = response.data.page; // ?หน้าที่เท่าไหร่
          this.total_pages = response.data.total_pages; //?หน้าทั้งหมด
          this.total = response.data.total; //? จำนวนข้อมูลทั้งหมด
          // console.log(response.data.data);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    ConfirmDelPackage(rowData) {
      Swal.fire({
        title: "ต้องการยกเลิกใบส่งมอบรถใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ลบ",
      }).then((result) => {
        if (result.value) {
          this.deletePackage(rowData);
        }
      });
    },
    onSearch() {
      if (this.branchIdSearch === "") {
        this.branchId = this.localDataBranch[0];
      } else {
        this.branchId = this.branchIdSearch.branchId;
      }
      // // console.log(this.branchId);
      this.handleSearch();
    },

    deletePackage: function(rowData) {
      // this.packageId = rowData;
      this.overlayFlag = true; //skeleton true
      useNetw
        .delete("api/sale-quotation/delete", {
          data: {
            saleId: rowData,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-5">
                <div class="mb-3 position-relative">
                  <label for="branchId">สาขา</label><br />
                  <multiselect
                    v-model="branchIdSearch"
                    :options="localData"
                    label="nameTh"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-3 position-relative">
                  <label>วันที่</label>
                  <date-picker
                    v-model="DateSearch"
                    :first-day-of-week="1"
                    format="YYYY-MM-DD"
                    value-type="format"
                    lang="en"
                  >
                  </date-picker>
                </div>
              </div>

              <div class="col-md-4">
                <div class="mb-3 position-relative">
                  <label for="branchId">สถานะ</label><br />
                  <multiselect
                    v-model="status"
                    :options="optionStatus"
                    label="name"
                  >
                  </multiselect>
                </div>
              </div>

              <div class="col-md-3">
                รหัสใบส่งมอบรถ :
                <label>&nbsp;</label><br />
                <b-form-input
                  v-model="returnCode"
                  type="search"
                  placeholder="รหัสใบส่งมอบรถ "
                  @keyup.enter="handleSearch"
                  class="form-control "
                ></b-form-input>
              </div>

              <div class="col-md-3">
                ชื่อลูกค้า :
                <label>&nbsp;</label><br />
                <b-form-input
                  v-model="cusNameSearch"
                  type="search"
                  placeholder="ชื่อลูกค้า "
                  @keyup.enter="handleSearch"
                  class="form-control "
                ></b-form-input>
              </div>
              <div class="col-md-3">
                นามสกุลลูกค้า :
                <label>&nbsp;</label><br />
                <b-form-input
                  v-model="cusFamilyNameSearch"
                  type="search"
                  placeholder="นามสกุลลูกค้า "
                  @keyup.enter="handleSearch"
                  class="form-control "
                ></b-form-input>
              </div>
              <div class="col-md-3">
                ทะเบียน :
                <label>&nbsp;</label><br />
                <b-form-input
                  v-model="vehicleSearch"
                  type="search"
                  placeholder="ทะเบียนรถ "
                  @keyup.enter="handleSearch"
                  class="form-control "
                ></b-form-input>
              </div>
              <div class="col-md-3 mt-3">
                <label for="cusSrcId"> ทีม:</label>
                <multiselect
                  id="cusSrcId"
                  v-model="teamSearch"
                  :options="teamOption"
                  :show-labels="false"
                  label="name"
                  track-by="teamId"
                  @search-change="getTeam($event)"
                >
                  <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-end">
                <label for="search">&nbsp;</label><br />
                <btnComponent @click="onSearch" changeStyle="search">
                </btnComponent>
                <router-link :to="{ name: 'vehicle-sale/form' }">
                  &nbsp;
                  <button class="btn btn-primary">
                    <i class="mdi mdi-plus me-1"></i>เพิ่ม
                  </button>
                </router-link>
              </div>
            </div>
            <br />

            <div class="row">
              <div class="col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    แสดงผล&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @input="handlePageChange"
                    ></b-form-select
                    >&nbsp;รายการ
                  </label>
                </div>
              </div>
            </div>
            <!-- End search -->
            <b-overlay
              :show="overlayFlag"
              :spinner-variant="this.$overlayVariant"
              :spinner-type="this.$overlayType"
              :rounded="this.$overlayRounded"
            >
              <tableData :fields="fields" :items="rowData">
                <template #cell(returnDate)="rowData">
                  <changeDate :date="rowData.item.returnDate"></changeDate>
                </template>
                <template #cell(action)="rowData">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link
                        href="javascript:void(0);"
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        :to="{
                          name: 'vehicle-sale/edit',
                          params: { saleId: rowData.item.saleId },
                        }"
                        title="Edit"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </router-link>
                    </li>
                    <li class="list-inline-item">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="ConfirmDelPackage(rowData.item.saleId)"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>

                    <li class="list-inline-item">
                      <i
                        href="javascript:void(0);"
                        class="px-2 text-success"
                        v-b-tooltip.hover
                        @click="printDocument(rowData.item.saleId)"
                        title="Print"
                      >
                        <i class="uil uil-print font-size-18"></i>
                      </i>
                    </li>
                  </ul>
                </template>
                <template #cell(status)="rowData">
                  <span
                    class="badge bg-warning font-size-12 ms-2"
                    v-if="rowData.item.status === 'DFT'"
                  >
                    ร่าง
                  </span>
                  <span
                    class="badge bg-success font-size-12 ms-2"
                    v-if="rowData.item.status === 'APV'"
                  >
                    ยืนยันแล้ว
                  </span>
                  <span
                    class="badge bg-success font-size-12 ms-2"
                    v-if="rowData.item.status === 'SUBMIT'"
                  >
                    ยืนยันแล้ว
                  </span>
                  <span
                    class="badge bg-danger font-size-12 ms-2"
                    v-if="rowData.item.status === 'CAN'"
                  >
                    ยกเลิก
                  </span>
                  <span
                    class="badge bg-success font-size-12 ms-2"
                    v-if="rowData.item.status === 'PAID'"
                  >
                    ชำระเงินแล้ว
                  </span>
                </template>
              </tableData>
              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.total }} รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
